<template>
  <div class="session1">
      <div class="left">
          <img src="@/assets/web/21.jpg" alt="" srcset="">
      </div>
      <div class="session-content">
          <div class="expo-right">
              <div class="expo-title">
                  <p class="e-title">课程博览馆简介/CURRICULUM EXPO</p>
                  <p class="shadow">CURRICULUM EXPO</p>
              </div>
              <p class="info">云巴蜀课程博览馆是由巴蜀小学核心团队研发，旨在打造学校精品课程资源库，帮助老师备课、上课。课程既有单元解读、又有单课目标分解，方便老师明晰教学脉络，把控教学目标，创生教学内容，是一个能很好地实现资源共享，师生共创的教学助手。</p>
                <img class="book-btn" src="@/assets/web/15.png" @click="bookAction" alt="" srcset="">
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "session-1",
    mounted(){
        setTimeout(() => {
            this.$animateCSS('.left', 'backInLeft', false).then(()=>{
                this.$animateCSS('.expo-right', 'fadeIn',false)
            })
        }, 600);
    },
    methods:{
        bookAction() {
            this.$router.push({ name: "map" });
        },
    }
}
</script>

<style lang="less">
.session1 {
    height: 502Px;
    background: white;
    position: relative;
    margin-top: 80Px;
    .left {
        position: absolute;
        left: 0;
        width: 752Px;
        height: 502Px;
        background: #F4F4F5;
        opacity: 0;
        img {
            width: 695Px;
            height: 463Px;
            margin-top: 20Px;
        }
    }
    @media only screen and (max-width: 1440Px){
        .left {
            position: absolute;
            left: 0;
            width: 600Px;
            height: 502Px;
            background: #F4F4F5;
            img {
                width: 580Px;
                height: 403Px;
                margin-top: 50Px;
            }
        }
    }
    .session-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 1170Px;
        margin: 0 auto;
        height: 460Px;
        position: relative;
        z-index: 100;
        
        .expo1 {
            height: 460Px;
        }
        .expo-right {
            text-align: left;
            padding-top: 60Px;
            opacity: 0;
            .expo-title {
                position: relative;
                .e-title {
                    font-size: 25Px;
                    font-weight: 800;
                    line-height: 23px;
                    color: #2A2A31;
                    letter-spacing: 2px;
                    padding-right: 40Px;
                }
                .shadow {
                    position: absolute;
                    top: -40Px;
                    right: 0;
                    font-size: 40Px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #D9D9D9;
                    opacity: 0.4;
                }
            }
            .info {
                width: 500Px;
                font-size: 17Px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 25Px;
                color: #2A2A31;
                margin-bottom: 40Px;
            }
            .book-btn {
                cursor: pointer;
            }
        }
    }
}
</style>