<template>
  <div class="index">
    <banner-1></banner-1>
    <session-1></session-1>
    <session-2></session-2>
    <session-3></session-3>
    <session-4></session-4>
    <div style="height: 80px">
      <to-top></to-top>
    </div>
  </div>
</template>

<script>
import Banner1 from "../../components/website/banners/Banner1.vue";
import Session1 from "../../components/website/sessions/Session1.vue";
import Session2 from "../../components/website/sessions/Session2.vue";
import Session3 from "../../components/website/sessions/Session3.vue";
import Session4 from "../../components/website/sessions/Session4.vue";
import ToTop from "../../components/website/ToTop.vue";

export default {
  components: {
    Banner1,
    Session1,
    Session2,
    Session3,
    Session4,
    ToTop,
  },
  data() {
    return {
      animated: 0,
    };
  },
  mounted() {
    window.onscroll = () => {
      let offset = window.pageYOffset;
      if (offset > 380 && this.animated < 1) {
        this.animated += 1;
        this.$animateCSS(".left-img", "fadeInLeft");
        this.$animateCSS(".right-img", "fadeInRight");
      }
      if(offset > 1110) {
        this.$animateCSS(".s-c-logo", "zoomIn", false).then(()=>{
          this.$animateCSS(".s-c-mid-item", "fadeIn", false)
        });
      }
    };
  },
  methods: {},
};
</script>

<style lang="less">

</style>