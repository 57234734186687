<template>
  <div class="session3">
      <div class="session3-warp">
          <div class="session3-title">
            <p class="title-left">Self - fetching lesson plans</p>
            <p class="title-right">自取式课案</p>
        </div>
        <div class="session3-content">
            <p class="s-c-title">“中央厨房”制造  核心团队研制</p>
            <p class="s-c-desc">““Central kitchen" manufacturing，core team development</p>
            <img class="s-c-logo" src="@/assets/web/16.png" alt="" srcset="">
            <p class="s-c-desc1">聚焦教师真实需要的品质课堂，创造孩子一生眷念的律动课堂</p>
            <div class="s-c-mid" :style="{background: 'url('+ bg + ')'}">
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
                <div class="s-c-mid-item">
                    活动
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>

import bg from "@/assets/web/18.png"
export default {
data() {
    return {
        bg:bg
    }
},

}
</script>

<style lang="less">
.session3 {

    height: 712Px;
    margin-bottom: 50Px;
    .session3-warp {
        width: 95%;
        margin: auto;
        .session3-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #2A2A31;
            .title-left {
                width: 120Px;
            }
            .title-right {
                position: relative;
            }
            .title-right::after {
                content: "";
                position: absolute;
                width: 21Px;
                height: 3Px;
                background: #AE0202;
                right: 0;
                top: -6Px;
            }
        }
        .session3-content {
            height: 612Px;
            background: #F4F4F5;
            color: #2A2A31;
            position: relative;
            .s-c-title {
                font-size: 25Px;
                font-weight: bold;
                text-align: center;
                margin: 0;
                padding-top: 49Px;
            }
            .s-c-desc {
                font-size: 14Px;
                color: #8E8E93;
                text-align: center;
                margin: 0;
            }
            .s-c-desc1 {
                color: #230A02;
                text-align: center;
                font-size: 12Px;
                position: relative;
                top: -30Px;
            }
            .s-c-logo {
                display: block;
                width: 473Px;
                height: 473Px;
                margin: 0 auto;
                position: relative;
                z-index: 100;
            }
            .s-c-mid {
                position: absolute;
                height: 112Px;
                width: 100%;
                background-size: 100% 100%;
                top: 257Px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                .s-c-mid-item {
                    width: 56Px;
                    height: 56Px;
                    background: #FFFFFF;
                    border: 1Px dotted rgba(255, 87, 0, 0.4);
                    box-shadow: 0px 3Px 7Px rgba(184, 184, 184, 0.4);
                    opacity: 1;
                    text-align: center;
                    line-height: 56Px;
                    color: #FF5700;
                    font-size: 12Px;
                    border-radius: 28Px;
                }
            }
        }
    }
}
</style>