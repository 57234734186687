<template>
  <div class="session2">
    <img class="left-img" src="@/assets/web/10.png" alt="" srcset="" />
    <img class="right-img" src="@/assets/web/5.jpg" alt="" srcset="" />
    <div class="session-2-content">
      <p class="title">课程博览馆入口</p>
      <p class="en-desc">the Curriculum Expo Enter</p>
      <img class="visite-btn" src="@/assets/web/17.png" @click="bookAction" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "session-2",
  data() {
    return {
        animated: 0
    };
  },
  mounted() {
    let self = this;
    window.onscroll = () => {
      
    };
  },
  methods:{
        bookAction() {
            this.$router.push({ name: "map" });
        },
    }
};
</script>

<style lang="less">
.session2 {
    height: 418Px;
    background:white;
    margin-top: 80Px;
    position: relative;
    margin-bottom: 60Px;
    .left-img {
        position: absolute;
        height: 502;
        left: 60Px;
        top: 80Px;
        z-index: 100;
    }
    .right-img {
        height: 418Px;
        position: absolute;
        right: 0;
    }
    .session-2-content {
        width: 500Px;
        height: 418Px;
        margin: 0 auto;
        padding-left: 0Px;
        position: relative;
        z-index: 1000;
        .title {
            padding-top: 197Px;
            font-size: 42Px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 23Px;
            color: #2A2A31;
        }
        .en-desc {
            font-size: 24Px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 23Px;
            color: rgba(0, 0, 0, 0.4);
        }
        .visite-btn {
            cursor: pointer;
        }
    }
}
</style>