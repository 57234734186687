<template>
  <div class="to-top" @click="gototop">
    <img src="@/assets/web/20.png" alt="" srcset="" />
    <span>回到顶部</span>
  </div>
</template>

<script>
export default {
  name: "to-top",
  methods:{
      gototop(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
  }
};
</script>

<style lang="less">
.to-top {
  width: 418Px;
  height: 35Px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: #ACACAC;
  color: white;
  span {
    padding-left: 10Px;
  }
}
</style>