<template>
  <div class="session4" :style="{backgroundImage: 'url(' + bg + ')'}">
      <img src="@/assets/web/8.png" class="s-4-l-i" alt="" srcset="">
      <div class="s-4-c">
          <div class="row1">
              <div class="r-l">
                  <p class="r-l-title">Why choose Bashu</p>
                  <p class="r-l-b-title">为什么选择巴蜀课程</p>
              </div>
              <div class="s-4-c-item">
                  <p class="s-4-c-title">如何创造孩子一生眷念的课堂？</p>
                  <p class="s-4-c-desc">聚焦学生、思维参与的律动课堂——“巴蜀公开课自主”
聚焦教师、真实需要的品质课堂——“火锅自助餐自取”
聚焦学校、团队研发的精品课堂—— “中央厨房制造”</p>
              </div>
          </div>
          <div class="row">
              <div class="s-4-c-item item-m">
                  <p class="s-4-c-title">如何创造孩子一生眷念的课堂？</p>
                  <p class="s-4-c-desc">聚焦学生、思维参与的律动课堂——“巴蜀公开课自主”
聚焦教师、真实需要的品质课堂——“火锅自助餐自取”
聚焦学校、团队研发的精品课堂—— “中央厨房制造”</p>
              </div>
              <div class="s-4-c-item item-m">
                  <p class="s-4-c-title">如何创造孩子一生眷念的课堂？</p>
                  <p class="s-4-c-desc">聚焦学生、思维参与的律动课堂——“巴蜀公开课自主”
聚焦教师、真实需要的品质课堂——“火锅自助餐自取”
聚焦学校、团队研发的精品课堂—— “中央厨房制造”</p>
              </div>
              <div class="s-4-c-item item-m">
                  <p class="s-4-c-title">如何创造孩子一生眷念的课堂？</p>
                  <p class="s-4-c-desc">聚焦学生、思维参与的律动课堂——“巴蜀公开课自主”
聚焦教师、真实需要的品质课堂——“火锅自助餐自取”
聚焦学校、团队研发的精品课堂—— “中央厨房制造”</p>
              </div>
              <div class="s-4-c-item item-m">
                  <p class="s-4-c-title">如何创造孩子一生眷念的课堂？</p>
                  <p class="s-4-c-desc">聚焦学生、思维参与的律动课堂——“巴蜀公开课自主”
聚焦教师、真实需要的品质课堂——“火锅自助餐自取”
聚焦学校、团队研发的精品课堂—— “中央厨房制造”</p>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import bg from "@/assets/web/9.jpg"
export default {
data() {
    return {
        bg:bg
    }
},
}
</script>

<style lang="less">
.session4 {
    height: 696Px;
    background-size: 957Px 696Px;
    background-repeat: repeat-x;
    position: relative;
    margin-bottom: 90Px;
    .s-4-l-i {
        position: absolute;
        height: 696Px;
        left: 0;
        top: 0;
    }
    .s-4-c {
        height: 696Px;
        width: 1000Px;
        margin: 0 auto;
        padding-left: 15%;
        padding-top: 125Px;
        .row1 {
            display: flex;
            .r-l {
                margin-right: 116Px;
                .r-l-title {
                    font-size: 12Px;
                    position: relative;
                    width: 80Px;
                }
                .r-l-title::before {
                    position: absolute;
                    content: '';
                    width: 21Px;
                    height: 3Px;
                    background: #801212;
                    left: 0;
                    top: -10Px;
                }
                .r-l-b-title {
                    font-size: 25Px;
                    color: #801212;
                    font-weight: bold;
                }
            }
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            .item-m {
                margin-right: 70Px;
                margin-top: 30Px;
            }
        }
        .s-4-c-item {
            width: 270Px;
            .s-4-c-title {
                font-size: 16Px;
                font-weight: bold;
            }
            .s-4-c-desc {
                font-size: 12Px;
            }
        }
        
    }
    @media  screen and (max-width: 1400Px) {
        .s-4-c {
            padding-left: 0;
            margin-left: 440Px;
        }
    }
    @media screen and (min-width: 1800px){
        .s-4-c {
            padding-left: 200px;
        }
    }
}
</style>