<template>
  <div class="banner1">
    <img class="right-pic" src="@/assets/web/1.png" alt="" srcset="" />
    <div class="banner-content">
      <div class="logo-warp">
        <img
          class="banner-logo"
          src="@/assets/banner-logo.png"
          alt=""
          srcset=""
        />
      </div>
      <p class="desc">“基于学科育人功能的课程综合化实施与评价”教学成果</p>
      <div class="shadow">
        <p class="shadow-title">课程博览</p>
        <p class="shadow-en">COURSE EXPO</p>
      </div>
      <p class="en-desc">
        Teaching achievements of "Comprehensive mplementation and Evaluation of
        Curriculum based on discipline education Function"
      </p>
      <div class="buttons">
        <img src="@/assets/web/15.png" @click="bookAction" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    bookAction() {
      this.$router.push({ name: "map" });
    },
  },
  mounted(){
    this.$animateCSS('.right-pic', 'backInRight').then(()=>{
      this.$animateCSS('.banner-content', 'fadeIn', false)
    })
  }
};
</script>

<style lang="less">
.banner1 {
    position: relative;
    background: white;
    height: 515Px;
    .right-pic {
        position: absolute;
        right: 0;
        height: 515Px;
        top: 0;
    }
    .banner-content {
        width: 1170Px;
        margin: 0 auto;
        opacity: 0;
        .logo-warp {
            padding-top: 37Px;
            .banner-logo {
                width: 150Px;
                height: 49Px;
            }
        }
        .desc {
            padding-top: 50Px;
            font-size: 15Px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 23Px;
            color: #2A2A31;
        }
        .shadow {
            position: relative;
            .shadow-title {
                font-size: 31Px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #801212;
                position: relative;
                z-index: 100;
            }
            .shadow-en {
                position: absolute;
                font-size: 31px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 23px;
                color: #D9D9D9;
                top: 28Px;
                left: 20Px;
                letter-spacing:2Px;
            }
        }
        .en-desc {
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 15Px;
            color: #2A2A31;
            width: 418Px;
            font-size: 13Px;
        }
        .buttons {
            padding-top: 40Px;
            img {
                margin-right: 15Px;
                cursor: pointer;
                width: 156Px;
                height: 48Px;
            }
        }
        .swpperBtn{
          margin-top: 74Px;
          img {
            height: 33Px;
            width: 33Px;
            margin-right: 15Px;
            cursor: pointer;
          }
        }
    }
}
</style>